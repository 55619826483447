import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import {
  mobilePortfolio,
  webPortfolio,
  gamePortfolio,
  databasePortfolio,
  designPortfolio,
  otherPortfolio,
} from "../../data";

export default function Portfolio() {
  const [selected, setSelected] = useState("mobile");
  const [data, setData] = useState([]);
  const list = [
    {
      id: "mobile",
      title: "Mobile Development",
    },
    {
      id: "web",
      title: "Web Development",
    },
    {
      id: "game",
      title: "Game Development",
    },
    {
      id: "database",
      title: "Database",
    },
    {
      id: "design",
      title: "Design",
    },
    {
      id: "other",
      title: "Other",
    },
  ];

  useEffect(() => {
    switch (selected) {
      case "mobile":
        setData(mobilePortfolio);
        break;
      case "web":
        setData(webPortfolio);
        break;
      case "game":
        setData(gamePortfolio);
        break;
      case "database":
        setData(databasePortfolio);
        break;
      case "design":
        setData(designPortfolio);
        break;
      case "other":
        setData(otherPortfolio);
        break;
      default:
        setData(mobilePortfolio);
    }
  }, [selected]);

  return (
    <div className="p" id="portfolio">
      <h1>Technologies & Skills</h1>
      <ul>
        {list.map((item) => (
          <PortfolioList
            title={item.title}
            active={selected === item.id}
            setSelected={setSelected}
            id={item.id}
          />
        ))}
      </ul>
      <div className="container">
        {data.map((d) => (
          <div className="item">
            <img src={d.img} alt="" />
            <h3>{d.title}</h3>
          </div>
        ))}
      </div>
      <a href="#works">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/about%2Farrow.png?alt=media&token=3fe9ef25-0018-437f-a0e6-f97e206af7aa"
          alt=""
        />
      </a>
    </div>
  );
}
