import "./about.scss";

export default function About() {
  const downloadFile = () => {
    window.location.href =
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/contact%2FOSMAN_CAN_CINAR_CV.pdf?alt=media&token=7ece99e7-5b0f-4859-bbb8-e756534cf169"
};

  return (
    <div className="a" id="about">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/about%2Fme.png?alt=media&token=da5648d6-3ede-4ecc-8a76-d7c4e9d9512c"
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
          I am a software engineer and I focus on App development for Mobile
          devices.
        </p>
        <p className="a-desc">
          I started developing apps while I was at Yasar University. I prefer
          developing applications for mobile devices on both IOS and Android. I
          develop both native and cross-platform apps. Also, I develop websites
          and games for mobile devices. I have a personal interest in Object
          Detection technologies with artificial intelligence and machine
          learning.
        </p>
        <div className="a-uni">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/about%2Fyasar.png?alt=media&token=3fa5228a-bc15-4b98-b6ac-eaf9157f1818"
            alt=""
            className="a-award-uni"
          />
          <div className="a-uni-texts">
            <h4 className="a-uni-title">
              Department of Software Engineering 2016 - 2021
            </h4>
            <p className="a-uni-desc">
              Yasar University is a university in İzmir, Turkey
            </p>
          </div>
        </div>
        <button className="button" onClick={downloadFile}>
          Download Resume
        </button>
      </div>
      <a href="#portfolio">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/about%2Farrow.png?alt=media&token=3fe9ef25-0018-437f-a0e6-f97e206af7aa"
          alt=""
        />
      </a>
    </div>
  );
}
