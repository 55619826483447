import "./intro.scss";
import { init } from "ityped";
import { useEffect, useRef } from "react";

export default function Intro() {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed: 60,
      strings: [
        "Software Engineering",
        "Mobile App Development",
        "Entrepreneurship",
        "Freelancing",
      ],
    });
  }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/intro%2Fme2.png?alt=media&token=49a3688b-12ab-4d02-88df-3e105d75e966"
            alt=""
          />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi there, I'm</h2>
          <h1>Osman Can Çınar</h1>
          <h3>
            and I do <span ref={textRef}></span>
          </h3>
        </div>
        <a href="#about">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/about%2Farrow.png?alt=media&token=3fe9ef25-0018-437f-a0e6-f97e206af7aa"
            alt=""
          />
        </a>
      </div>
    </div>
  );
}
