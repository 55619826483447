import "./topbar.scss";
import { Person, Mail } from "@material-ui/icons";

export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen === true && "active")}>
      <div className="wrapper">
        <div className="left">
          <div className="phoneNumber">
            <Person className="icon" />
            <span>+90 539 677 93 53</span>
          </div>
          <div className="email">
            <Mail className="icon" />
            <span>osmancandev@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
  );
}
