export const mobilePortfolio = [
  {
    id: 1,
    title: "Android Native development with Java",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fandroid-java.png?alt=media&token=f5e7a285-2658-47ad-9689-fc1ac8c96050",
  },
  {
    id: 2,
    title: "Android Native development with Kotlin",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2FAndroid-Kotlin.png?alt=media&token=79584ae9-24fe-42e2-8514-85fc5fa55580",
  },
  {
    id: 3,
    title: "Cross-platform development with Dart",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fflutter-dart.png?alt=media&token=db65e5f1-61d0-4177-9a7a-4f712064d4f8",
  },
  {
    id: 4,
    title: "IOS Native development with Swift",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2FIOS-Swift.png?alt=media&token=5204e9ae-4a43-4692-9a03-c8c84d7d6f00",
  },
];

export const webPortfolio = [
  {
    id: 1,
    title: "React.js for Frontend development",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Freact.png?alt=media&token=8e6f4dd6-deaf-4791-879d-17c3b147ec0c",
  },
  {
    id: 2,
    title: "Node.js for Backend development",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fnodejs.png?alt=media&token=a319e978-bf87-417b-92dd-4418cbe9d16d",
  },
  {
    id: 3,
    title: "HTML 5 & CSS 3 for styling",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fhtml-css.png?alt=media&token=d8ac9101-f135-4802-b35f-77c01563a931",
  },
];

export const gamePortfolio = [
  {
    id: 1,
    title: "Unity, cross-platform game engine ",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Funity.png?alt=media&token=8fb69275-e314-4afd-a169-384f8ca3ff57",
  },
  {
    id: 2,
    title: "C# for Programming",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fcsharp.png?alt=media&token=6cfead35-e69b-4f1a-a62f-12f49d02a745",
  },
];

export const databasePortfolio = [
  {
    id: 1,
    title: "Firebase for cloud based solutions",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Ffirebase.png?alt=media&token=76d153f4-900c-4077-aa25-55dafc934a39",
  },
  {
    id: 2,
    title: "SQLite for mobile local storage",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fsqlite.png?alt=media&token=6a1c1874-095a-4ff0-923f-e0e7cec87c71",
  },
  {
    id: 3,
    title: "MySql",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fmysql.png?alt=media&token=cb32c106-7433-405f-97b7-61fdb397b29b",
  },
];

export const designPortfolio = [
  {
    id: 1,
    title: "Adobe XD for detailed designs",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fxd.png?alt=media&token=1218fb57-df1d-42f0-80e2-f53b882f831f",
  },
  {
    id: 2,
    title: "Figma  for flows",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Ffigma.png?alt=media&token=6e8c829e-0ea7-4953-91ec-deca3372c2bc",
  },
];

export const otherPortfolio = [
  {
    id: 1,
    title: "NetLogo for multi-agent programmable modeling",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fnetlogo.png?alt=media&token=adc3218f-6939-48c1-9ff8-7183fc705514",
  },
  {
    id: 2,
    title: "Robot Operating System",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fros.png?alt=media&token=79bf6f4a-449c-4843-b28b-4a20264a954e",
  },
  {
    id: 3,
    title: "Python for Discord Bots, Data Structures, and Machine Learning",
    img: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/data%2Fpython.png?alt=media&token=e15009df-526f-4761-a906-658a20d8bc5c",
  },
];

export const worksData = [
  {
    id: "1",
    icon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fvela.png?alt=media&token=6dd2e225-a58d-4355-8e52-0adc13114f72",
    title: "Vela Application - Flutter",
    desc: "Vela is a digital service which shows local eateries and deals nearby to individuals looking to explore new eating out experiences. On the business side, Vela allows local businesses, specifically cafés and restaurants, to create promotions in order to increase traffic and awareness. In other words, Vela connects users looking to explore new restaurants and local businesses looking to drive traffic through promotions, eventually increasing their awareness. Working as Developer and Founder.",
    labelIcon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fwebsite.png?alt=media&token=ba2614f7-7dca-45b7-8171-0c26161d2deb",
    labelIcon2: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Finstagram.png?alt=media&token=e3bc2620-0b80-4345-b477-915a9e9b3244",
    label: "Website",
    label2: "Instagram",
    link: "https://www.vela.app",
    link2: "https://instagram.com/vela.app?igshid=YmMyMTA2M2Y=",
    tech: [
      "Working with Custom APIs",
      "Coded in Dart language with Flutter for both Android OS and IOS",
      "MapBox Integration",
      "Product Development",
      "Creating a Community",
      "Repository Pattern with Riverpod State Management",
    ],
    img: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fvelaapp%2Fvela_1.png?alt=media&token=bfb3555d-2e89-4d14-8c73-410d19734732",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fvelaapp%2Fvela_2.png?alt=media&token=536b7168-8def-46f1-83da-fc740f7f8f42",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fvelaapp%2Fvela_3.png?alt=media&token=601a4f7d-f7f5-4f64-9f3c-15161831583a",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fvelaapp%2Fvela_4.png?alt=media&token=2fb6067b-a26f-4eec-9769-3945e3aa2591",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fvelaapp%2Fvela_5.png?alt=media&token=1d49bcc9-e7e0-4655-a4ed-174280e816d5",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fvelaapp%2Fvela_6.png?alt=media&token=39b5a325-0c7c-40a9-ab32-77ef17a16858",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fvelaapp%2Fvela_7.png?alt=media&token=16ae3912-550e-4c44-b5dc-51fdafd40bc7",
    ],
  },
  {
    id: "2",
    icon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2FmoviesApp.png?alt=media&token=9b37b3d0-78ad-4c1d-a513-f871459fb43c",
    title: "Movies Application - Android Kotlin",
    desc: "Movies App displays popular and top-rated movies. Lists display details about movies. User can browse, and share movies that they are interested in. It works with TMDB. Worked as Developer and Founder.",
    labelIcon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fgithub.png?alt=media&token=752ec3c2-4cc4-4df3-9b18-05580f3bba0c",
    labelIcon2: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fplay-store.png?alt=media&token=16812e01-cea2-4657-a033-e0b07699c18a",
    label: "Github ",
    label2: "Google Play Store",
    link: "https://github.com/OsmanCanCinar/Movies-App",
    link2:
      "https://play.google.com/store/apps/details?id=com.osmancancinar.moviesapp",
    tech: [
      "Working with APIs",
      "Coded in Kotlin language for Android OS",
      "Offline Caching",
      "Designing in Figma",
      "Local Data Storing and CRUD Operations",
      "Ad Serving",
    ],
    img: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fmoviesapp%2Fmovies_1.png?alt=media&token=88a0c790-882a-436a-9abb-31b0a0c55a5f",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fmoviesapp%2Fmovies_2.png?alt=media&token=41f0e21f-ba8a-4477-9426-58997bbedf0a",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fmoviesapp%2Fmovies_3.png?alt=media&token=639fd186-1787-4be7-9029-b76492ebae62",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fmoviesapp%2Fmovies_4.png?alt=media&token=79ee3d97-a653-4bc5-8211-c63f0d3d1477",
    ],
  },
  {
    id: "3",
    icon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2FtodoApp.png?alt=media&token=912a5805-5f4c-459e-ad45-5af63e60ea90",
    title: "To Do Application - Android Kotlin",
    desc: "This is a simple To Do List App which helps the user to keep track of their tasks. It has in-app instructions page. User can add, edit, delete and list your tasks.Coded in Kotlin language for Android OS. Worked as Developer and Founder.",
    labelIcon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fgithub.png?alt=media&token=752ec3c2-4cc4-4df3-9b18-05580f3bba0c",
    labelIcon2: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fplay-store.png?alt=media&token=16812e01-cea2-4657-a033-e0b07699c18a",
    label: "Github",
    label2: "Google Play Store",
    link: "https://github.com/OsmanCanCinar/ToDoApp",
    link2:
      "https://play.google.com/store/apps/details?id=com.osmancancinar.todoapp",
    tech: [
      "Offline Caching",
      "Local Data Storing, CRUD Operations",
      "Coded in Kotlin language for Android OS",
      "Designing in Adobe XD",
      "MVVM Architecture",
      "Google Play Store Updates",
    ],
    img: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ftodoapp%2Ftodo_1.png?alt=media&token=76a96c62-7c79-4cf5-aa3d-af18dca50fce",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ftodoapp%2Ftodo_2.png?alt=media&token=85f8e7e7-80b4-405a-bff4-e1643dd6ae3f",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ftodoapp%2Ftodo_3.png?alt=media&token=1523fd95-5337-4be0-87d3-7d2021378f16",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ftodoapp%2Ftodo_4.png?alt=media&token=f48b53f2-89d2-453b-aa15-1ad7c97cb99d",
    ],
  },
  {
    id: "4",
    icon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ffitcom.png?alt=media&token=28b4e940-64bf-4063-ad34-e8586e311fa8",
    title: "Fitcom Application - Android Java",
    desc: "The FitCom application is a mobile platform that aims to reach users who want to make fitness a part of their daily life and be an ideal platform for fitness trainers to reach their clients. The intended customers are both trainers and ppl who wants do exercise(clients).By the usage of this application, people can reach their fitness goals easily. FitCom connects its users with professional trainers on this platform so that user can save their time and money at the same timeWorking with APIs. ",
    labelIcon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fgithub.png?alt=media&token=752ec3c2-4cc4-4df3-9b18-05580f3bba0c",
    labelIcon2: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fplay-store.png?alt=media&token=16812e01-cea2-4657-a033-e0b07699c18a",
    label: "Github",
    label2: "Google Play Store",
    link: "https://github.com/cenkatahan/FitCom",
    link2:
      "https://play.google.com/store/apps/details?id=com.FitCom.fitcomapplication",
    tech: [
      "Coded in Java language for Android OS",
      "Cloud database with Firebase",
      "Cloud CRUD Operations",
      "Designing in Figma",
      "Log In and Sign Up",
      "Deployment to Google Play Store",
    ],
    img: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ffitcom%2Ffitcom_1.png?alt=media&token=e020cc97-ce51-4f8a-9caa-94dfe067279e",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ffitcom%2Ffitcom_2.png?alt=media&token=b651be29-e9a3-4df6-9d5e-69ea3e88e35f",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ffitcom%2Ffitcom_3.png?alt=media&token=33032947-1b11-49a6-8bde-186c12b48acc",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ffitcom%2Ffitcom_4.png?alt=media&token=dcb6021f-5521-48bd-adce-eecf3fe6bc7c",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ffitcom%2Ffitcom_5.png?alt=media&token=4599424b-9793-4f3d-8d2c-4a7881f0455f",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ffitcom%2Ffitcom_6.png?alt=media&token=79d56ec8-6d76-42ad-a40e-e4809fa022cd",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Ffitcom%2Ffitcom_7.png?alt=media&token=81f60d60-dcce-4de9-83f7-c78eae668746",
    ],
  },
  {
    id: "5",
    title: "Rascal Penguins NFT Project",
    icon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2FrascalPenguins.png?alt=media&token=35fe040d-1d8d-4eaf-8de2-a094ab413cd5",
    desc: "It is a NFT Project on Ethereum Blockchain. Goal of the Project is creating an active community with privileged owners. Worked as a Developer and Founder.",
    labelIcon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fwebsite.png?alt=media&token=ba2614f7-7dca-45b7-8171-0c26161d2deb",
    labelIcon2: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Ftwitter.png?alt=media&token=dcd31887-3dcf-458f-b756-c8dfb9a48ad9",
    label: "Website",
    label2: "Twitter",
    link: "https://rascalpenguins.com",
    link2: "https://twitter.com/RascalPenguins?s=20&t=lITClEBeDIADO8ywpSBfIg",
    tech: [
      "Discord Bot Coding in Python (Games, Ranking system)",
      "ERC-721 Smart Contract coding in Solidity",
      "Website development and Web3 integration",
      "Roadmap Planning and Community Management",
      "NFT Generation and other utilities",
      "Product Development",
    ],
    img: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Frascalnft%2Ffish_game.png?alt=media&token=f656b72d-eae8-4377-a623-d84e74ed63e3",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Frascalnft%2Frank.png?alt=media&token=55ab2f97-9440-4c6e-976f-d5898560e773",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Frascalnft%2FFP1ZegvWUAc0kh-.jpg?alt=media&token=87d32a5b-0f1c-41ac-91e5-66f8d2bcd4b5",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Frascalnft%2Frascal.png?alt=media&token=9754afa0-19f2-4cbe-816d-99005ff68031",
    ],
  },
  {
    id: "6",
    icon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fyoga.png?alt=media&token=35901660-2c0d-4d92-868f-f01ef7aaf51f",
    title: "Yoga Application - Android Kotlin",
    desc: "It is an exercise application coded in Kotlin for Android platform. Users can sign up/in to the application by email, Facebook and Google. There are yoga videos, audio meditation records, and articles. Worked as Developer, Content Creator and Founder.",
    labelIcon: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fgithub.png?alt=media&token=752ec3c2-4cc4-4df3-9b18-05580f3bba0c",
    labelIcon2: "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Flink-icons%2Fplay-store.png?alt=media&token=16812e01-cea2-4657-a033-e0b07699c18a",
    label: "Github",
    label2: "Google Play Store",
    link: "https://github.com/OsmanCanCinar/Android_Kotlin_Yoga_App",
    link2: "https://osmancancinar.com",
    tech: [
      "Coded in Kotlin language for Android OS",
      "Firebase for backend services such as authentication, nosql database, storage and analytics",
      "Media Player and Controller for both audio and video player",
      "Custom designed UI with Adobe XD",
      "Product development ",
      "MVVM Architecture",
    ],
    img: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fyogaapp%2Fyoga_1.png?alt=media&token=e2d469f5-ef09-4802-8a6a-da570f613ff4",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fyogaapp%2Fyoga_2.png?alt=media&token=9f61b627-a81e-453d-92a0-8de37ab31d69",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fyogaapp%2Fyoga_3.png?alt=media&token=b913c247-2511-4b19-b6e8-3f1e85b6c5a1",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fyogaapp%2Fyoga_4.png?alt=media&token=9245a45b-f7f7-4216-8d72-09d306c06041",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fyogaapp%2Fyoga_5.png?alt=media&token=d1853637-366d-459e-a0bb-8cd5a491441d",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fyogaapp%2Fyoga_6.png?alt=media&token=c7576f29-6e2d-4497-822e-01bda87806da",
    ],
  },
];
