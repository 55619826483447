import "./contact.scss";
import { useState } from "react";
import emailjs from "emailjs-com";
import FormInput from "./form_input/FormInput";

export default function Contact() {
  const [done, setDone] = useState(false);

  const [values, setValues] = useState({
    username: "",
    subject: "",
    email: "",
    message: "",
  });

  const inputs = [
    {
      id: 1,
      name: "user_name",
      type: "text",
      placeholder: "Name",
      errorMessage:
        "Username should be 3-16 characters and shouldn't include any special character!",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
    },
    {
      id: 2,
      name: "user_subject",
      type: "text",
      placeholder: "Subject",
    },
    {
      id: 3,
      name: "user_email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      required: true,
    },
    {
      id: 4,
      name: "message",
      type: "textarea",
      placeholder: "Message",
      errorMessage: "Please give more details!",
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_mgj2883",
        "template_xnnuj3e",
        values,
        "user_p9Qnns9eehlcsHW8GuHne"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="c" id="contact">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Let's discuss your project</h1>
          <div className="c-info">
            <div className="c-info-item">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/contact%2Fphone.png?alt=media&token=6d6ebaa6-6c08-47a1-aa06-4992a9fe2313"
                alt=""
                className="c-icon"
              />
              +90 539 677 93 53
            </div>
            <div className="c-info-item">
              <img
                className="c-icon"
                src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/contact%2Femail.png?alt=media&token=04abbc4f-4664-472b-8d60-bd3252905bce"
                alt=""
              />
              osmancandev@gmail.com
            </div>
            <div className="c-info-item">
              <img
                className="c-icon"
                src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/contact%2Faddress.png?alt=media&token=6073669e-af42-4431-a081-035653b428fa"
                alt=""
              />
              Izmir, Turkey
            </div>
            <div className="c-info-link" onClick={ event => window.location.href="https://www.linkedin.com/in/osman-can-%C3%A7%C4%B1nar-56041319a" 
              }>
              <img
                className="c-icon"
                src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/contact%2FlinkedIn.png?alt=media&token=a1bdd1d1-dbea-45bf-adf8-1bf2f56cd510"
                alt=""
              />
              LinkedIn
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <b>What’s your story?</b> Get in touch. Always available for
            freelancing if the right project comes along.
          </p>
          <form onSubmit={handleSubmit}>
            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <button>Submit</button>
            {done && "Thanks, I'll reply ASAP :)"}
          </form>
        </div>
      </div>
    </div>
  );
}
