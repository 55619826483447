import { useState } from "react";
import "./works.scss";
import { worksData } from "../../data";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

export default function Works() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleClick = (way) => {
    way === "left"
      ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 2)
      : setCurrentSlide(
          currentSlide < worksData.length - 1 ? currentSlide + 1 : 0
        );
  };

  return (
    <div className="works" id="works">
      <div
        className="slider"
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {worksData.map((d) => (
          <div className="container">
            <div className="item">
              <div className="left">
                <div className="left">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <img src={d.img[0]} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={d.img[1]} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={d.img[2]} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={d.img[3]} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={d.img[4]} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={d.img[5]} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={d.img[6]} alt="" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div className="right">
                <div className="rightContainer">
                  <div className="imgContainer">
                    <img src={d.icon} alt="" />
                  </div>
                  <h2>{d.title}</h2>
                  <p>{d.desc}</p>
                  <ul className="list">
                    <li>{d.tech[0]}</li>
                    <li>{d.tech[1]}</li>
                    <li>{d.tech[2]}</li>
                    <li>{d.tech[3]}</li>
                    <li>{d.tech[4]}</li>
                    <li>{d.tech[5]}</li>
                  </ul>
                  <div
                  className="link-container">
                    <div
                      className="c-info-link"
                      onClick={(event) => (window.location.href = d.link)}
                    >
                      <img className="c-icon" src={d.labelIcon} alt="" />
                      {d.label}
                    </div>
                    <div
                      className="c-info-link"
                      onClick={(event) => (window.location.href = d.link2)}
                    >
                      <img className="c-icon" src={d.labelIcon2} alt="" />
                      {d.label2}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fside.png?alt=media&token=33370e96-4fe2-4b6c-922b-c2389b776859"
        className="arrow left"
        alt=""
        onClick={() => handleClick("left")}
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/works%2Fside.png?alt=media&token=33370e96-4fe2-4b6c-922b-c2389b776859"
        className="arrow right"
        alt=""
        onClick={() => handleClick()}
      />
      <a href="#contact">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-web-site-e0e8e.appspot.com/o/about%2Farrow.png?alt=media&token=3fe9ef25-0018-437f-a0e6-f97e206af7aa"
          alt=""
        />
      </a>
    </div>
  );
}
